import Container from './Container';
import animate from 'ant-design-vue/es/_util/css-animation';
import {isEvent, isNumber} from "@/resources/util";
import hasProp from "ant-design-vue/es/_util/props-util";
import DropdownSubMenu from './SubMenu';
import Dropdown from "./Dropdown";
import EventMixin from "@/mixins/EventMixin";

export default {

	name: "GDropdownMenu",
	mixins: [EventMixin],
	inject: {

		dropdown: {},
		dropdownMenu: {default: null}
	},
	props: {

		prefixCls: {type: String, default: null},
		classesSuffixes: {type: [Object, String, Array], default: null},
		appendTo: {type: [Element, Function], default: null}
	},
	provide(){

		return {dropdownMenu: this}
	},
	created(){

		this.currentMenuItemEl = null;
		this.dropdown.addChildMenuVm(this);
		this.items = [];
	},
	data(){

		let style = {};

		return {

			isVisible: false,
			style
		}

	},
	methods: {

		setItemsVisible(is, e){

			this.items.map((vm) => {

				vm.setIsVisible && vm.setIsVisible(is, e);
			})
		},
		hasChildEl(el){

			return this.$el.contains(el);
		},
		addItemVm(vm){

			this.items.push(vm);
		},
		getAppendTo(){

			return this.appendTo;
		},
		getParentMenu(){

			return this.dropdownMenu || this.dropdown;
		},
		isRoot(){

			return this.getParentMenu().$options.name === Dropdown.name;
		},
		isOpen(){

			return this.isVisible;
		},
		focus(e = {}){

			if(isEvent(e, ['mouseenter', 'mouseleave'])) return;

			let $el = this.getMenuRef();

			if(e instanceof KeyboardEvent) {

				(~[9,27,37].indexOf(e.keyCode) ? this.currentMenuItemEl : (this.currentMenuItemEl = $el[e.keyCode === 38 ? 'lastChild' : 'firstChild'])).focus();

			} else if(isNumber(e)) {

				(this.currentMenuItemEl = $el.childNodes[e]).focus();

			} else if(this.currentMenuItemEl) {

				this.currentMenuItemEl.focus();

			} else {

				$el.focus();
			}
		},
		setCurrentItemEl(el){

			this.currentMenuItemEl = el;
		},
		getCurrentItemIndex(){

			return Array.prototype.indexOf.call(this.getMenuRef().childNodes, this.currentMenuItemEl);
		},
		getCurrentItemVNode(){

			return this.$slots.default[this.getCurrentItemIndex()];
		},
		getCurrentItemVm(){

			let vm = this.getCurrentItemVNode();
			return vm && vm.componentInstance;
		},
		isCurrentItemSubMenuVNode(){

			let current = this.getCurrentItemVNode();
			return current && current.componentInstance.$options.name === DropdownSubMenu.name;
		},
		handleOpenSubMenu(is, e){

			if(!is){

				this.handleVisibleChange(is, e);

			} else {

				if (!this.isCurrentItemSubMenuVNode()) return;
				this.getCurrentItemVm().setIsVisible(is, e, 'parent');
			}
		},
		handleVisibleChange(is, e){

			if(this.isRoot()){

				this.getParentMenu().handleVisibleChange(false, e);

			} else {

				const parent = this.getParentMenu();
				parent.getCurrentItemVm().setIsVisible(is, e);
				parent.focus(e);
			}
		},
		handleCurrentItemClick(e){

			if(this.isCurrentItemSubMenuVNode()){

				this.handleOpenSubMenu(true, e);

			} else if(this.currentMenuItemEl) {

				this.currentMenuItemEl.click();
			}
		},
		getTransitionProps(){

			const transitionName = 'slide-up';

			return {

				on: {

					enter: (el, done) => {

						animate(el, `${transitionName}-enter`, done);
					},
					leave: (el, done) => {

						animate(el, `${transitionName}-leave`, done);
					}
				}
			};
		},
		setIsVisible(is, e, pivot = 'axis') {

			if(!!is === this.isVisible){

				if(is){

					this.isVisible = false;

					this.$nextTick(() => {

						if(e) this.setPosition(e, pivot);
						this.isVisible = true;
						this.focus(e);
					})
				}

				return;
			}

			this.isVisible = !!is;

			if (is){

				if(e) this.setPosition(e, pivot);

				this.$nextTick(() => {

					this.focus(e);
				})

			} else {

				this.currentMenuItemEl = null;
			}
		},
		setPosition(e, pivot = 'axis'){

			let x;
			let y;

			if(e.type === "contextmenu"){

				x = e.clientX;
				y = e.clientY;

			} else {

				let rect = (e instanceof Event ? e.target : e).getBoundingClientRect();
				let selfRect = this.$el.getBoundingClientRect();

				x = pivot === 'axis' ? rect.x : rect.width + 6;
				y = pivot === 'axis' ? rect.y + rect.height + 4 : rect.y - selfRect.y - 4;
			}

			this.style = Object.assign(this.style, {

				top: `${y}px`,
				left: `${x}px`
			});
		},
		getMenuRef(){

			return this.$refs.dropdownMenu;
		},
		getMenuWrapperRef(){

			return this.$refs.wrapper;
		},
		renderComponent(){

			// let defClassesSuffixes = ['vertical', 'root', 'light'];
			let defClassesSuffixes = ['placement-bottomLeft'];

			if(this.classesSuffixes){

				defClassesSuffixes = this.classesSuffixes;
			}

			let classes = [];

			for(let k in defClassesSuffixes){

				classes.push(this.prefixCls + '-' + defClassesSuffixes[k]);
			}

			classes.unshift(this.prefixCls);

			let wrapperProps = {

				ref: 'wrapper',
				style: this.style || {},
				class: classes,
				//ant-dropdown-menu-submenu ant-dropdown-menu-submenu-popup ant-dropdown-menu-light ant-dropdown-menu-submenu-placement-rightTop
				// class: `${this.prefixCls} ${this.prefixCls}-vertical ${this.prefixCls}-root ${this.prefixCls}-light`,
				// class: 'ant-dropdown ant-dropdown-placement-bottomLeft',
				directives: [{name: 'show', value: this.isVisible}]
			};

			if(!this.isRoot() || this.getParentMenu().isTriggerEventMouseEnter()){

				wrapperProps.on = {

					mouseleave: (e) => {

						if(this.mouseLeaveTimeout) clearTimeout(this.mouseLeaveTimeout);

						let {toElement} = e;

						let el = this.$parent.$el;

						if(el && (el === toElement || el.contains(toElement))){

							return;
						}

						this.mouseLeaveTimeout = setTimeout(() => {

							if(this.isRoot()){

								this.getParentMenu().handleVisibleChange(false, e);

							} else {

								if (this.isVisible) this.$parent.setIsVisible(false, e);
							}

						}, 250);
					},
					mouseenter: (e) => {

						clearTimeout(this.mouseLeaveTimeout);
					}
				}
			}

			let props = {

				ref: 'dropdownMenu',
				attrs: {tabindex: 0},
				class: 'ant-dropdown-menu ant-dropdown-menu-vertical ant-dropdown-menu-root ant-dropdown-menu-light ant-dropdown-content',
				on: {

					keydown: (e) => {

						let {keyCode, target, shiftKey} = e;
						let $el = this.getMenuRef();

						switch(keyCode){

							//Tab
							case 9: {

								this.currentMenuItemEl = e.target[shiftKey ? 'previousElementSibling' : 'nextElementSibling'];

								if(!this.currentMenuItemEl){

									e.preventDefault();
									e.stopPropagation();

									(this.currentMenuItemEl = this.getMenuRef()[shiftKey ? 'lastChild' : 'firstChild']).focus();
								}

								break;
							}

							//Enter
							case 13: {

								this.handleCurrentItemClick(e);
								break;
							}

							//Esc
							case 27: {

								this.handleVisibleChange(false, e);
								break;
							}

							//Left
							case 37: {

								// this.setIsVisible(false, e);
								this.handleOpenSubMenu(false, e);
								break;
							}

							//Up
							case 38: {

								let prev;

								if (target === $el) {

									prev = $el.lastChild;

								} else {

									prev = target.previousElementSibling;

									while(prev && (prev.classList.contains('ant-dropdown-menu-item-disabled') || prev.classList.contains('ant-dropdown-menu-submenu-disabled') || prev.classList.contains('ant-dropdown-menu-item-divider'))){

										prev = prev.previousElementSibling;
									}
								}

								if (prev) {

									prev.focus();
									this.currentMenuItemEl = prev;
								}

								break;
							}

							//Right
							case 39: {

								this.handleOpenSubMenu(true, e);
								break;
							}

							//Down
							case 40: {

								let next;

								if (target === $el) {

									next = $el.firstChild;

								} else {

									next = target.nextElementSibling;

									while(next && (next.classList.contains('ant-dropdown-menu-item-disabled') || next.classList.contains('ant-dropdown-menu-submenu-disabled') || next.classList.contains('ant-dropdown-menu-item-divider'))){

										next = next.nextElementSibling;
									}
								}

								if (next) {

									next.focus();
									this.currentMenuItemEl = next;
								}

								break;
							}
						}
					}
				}
			};

			return (

				<transition {...this.getTransitionProps()}>
					<div {...wrapperProps}>
						<ul {...props}>
							{this.$slots.default}
						</ul>
					</div>
				</transition>
			)
		}
	},
	render(){

		let containerProps = {

			ref: 'container',
			props: {

				appendTo: this.getAppendTo(),
				renderComponent: this.renderComponent
			}
		};

		return (

			<Container {...containerProps}/>
		)
	}
}