import './style/index.less';

import Menu from './Menu';
import MenuItem from './MenuItem';
import SubMenu from './SubMenu';
import MenuItemDivider from './MenuItemDivider';

import EventMixin from "@/mixins/EventMixin";
import {hasProp, getComponentFromProp, slotHasProp, getPropsData} from "ant-design-vue/es/_util/props-util";
import {cloneElement} from "ant-design-vue/es/_util/vnode";
import {isArray, isBoolean, isEvent} from "@/resources/util";

const Dropdown = {

	Menu,
	MenuItem,
	MenuItemDivider,
	SubMenu,
	name: "GDropdown",
	mixins: [EventMixin],
	props: {

		visible: {type: [Boolean, Event], default: false},
		trigger: {type: Array, default: () => []}
	},
	provide(){

		return {dropdown: this}
	},
	created(){

		this.menus = [];

		if(this.hasVisibleProp()){

			if(this.visible){

				this.$nextTick(() => {

					this.setIsVisible(this.visible, isBoolean(this.visible) ? this.$el : this.visible);
				})
			}

			this.$watch('visible', (n, o) => {

				this.setIsVisible(n, isEvent(n) ? n : this.$el);

			}, {immediate: false})
		}
	},
	mounted(){


	},
	data(){

		return {

			isVisible: false,
			wasVisible: false,
			style: {}
		}
	},
	methods: {

		addChildMenuVm(vm){

			this.menus.push(vm);
		},
		hasVisibleProp(){

			return hasProp(this, 'visible');
		},
		handleVisibleChange(is, e){

			if(this.hasVisibleProp()) {

				this.$emit('visibleChange', is, e);

			} else {

				this.setIsVisible(is, e);
			}
		},
		handleGoOut(){

			this.menus.map(vm => {

				vm.items.map(vm => vm.setIsVisible && vm.setIsVisible(false));
				vm.setIsVisible(false);
			});
		},
		handleItemClick(key, e){

			this.$emit('itemClick', key, e);
			this.handleVisibleChange(false, e);
		},
		setIsVisible(is, e){

			// console.log('setIsVisible', {visible: this.visible, isVisible: this.isVisible, is, e});

			if(isEvent(is)){

				this.triggerEvent = is;
			}

			this.handleTriggerClickEvent(e);

			if(!this.wasVisible && is){

				this.wasVisible = true;

				this.$nextTick(() => {

					this.isVisible = true;
				})

			} else {

				this.isVisible = is;
			}

			if(is){

				this.$nextTick(() => {

					const menu = this.getComponent();

					if(menu) {

						// menu.setIsVisible(is, this.visibleChangeEvent||e);
						menu.setIsVisible(is, e);
					}
				});

			} else {

				this.focus();
				this.handleGoOut();
			}
		},
		focus(){

			if(isEvent(this.triggerEvent)){

				this.triggerEvent.target.focus();

			} else {

				this.$el.focus();
			}
		},
		handleTriggerClickEvent(e = null){

			let {type = null} = e || {};

			if(type === 'contextmenu'){

				e.preventDefault();
			}

			if(this.onClickOutsideEvent) return;

			this.onClickOutsideEvent = (e) => {

				if(!this.isVisible) return;

				let wrapper = this.getComponent();
				if(wrapper) wrapper = wrapper.$el;
				else return;

				if (e.target !== this.$el && !this.$el.contains(e.target) && e.target !== wrapper && !wrapper.contains(e.target)) {

					this.handleVisibleChange(false, e);
				}
			};

			this.$onEvent(window, 'click', this.onClickOutsideEvent, true);

			// this.$nextTick(() => {
			//
			// 	this.$onEvent(this.getComponent().$el, 'click', this.onClickOutsideEvent);
			// });
		},
		isTriggerEventMouseEnter(){

			return ['mouseenter'].indexOf((this.triggerEvent||{}).type) > -1;
		},
		handleTriggerEvent(e){

			let {type} = e;

			this.triggerEvent = e;

			switch (type) {

				case 'mouseenter': {

					let timeout = {enter: 100, leave: 200};

					if(this.mouseEnterTimeout) clearTimeout(this.mouseEnterTimeout);
					if(this.mouseLeaveTimeout) this.mouseLeaveTimeout = clearTimeout(this.mouseLeaveTimeout);

					this.mouseEnterTimeout = setTimeout(() => {

						this.handleVisibleChange(true, this.$el);

						if(!this.isTriggerEventMouseEnter()) return;

						if (this.onMouseLeaveEvent) return;

						this.$nextTick(() => {

							this.onMouseLeaveEvent = (e) => {

								let wrapper = this.getComponent();
								if(wrapper) wrapper = wrapper.$el;
								if(!wrapper) return;

								if (this.isVisible && e.toElement !== this.$el && !this.$el.contains(e.toElement) && e.toElement !== wrapper && !wrapper.contains(e.toElement)) {

									this.mouseLeaveTimeout = setTimeout(() => {

										this.handleVisibleChange(false, e);

									}, timeout.leave);
								}
							};

							// this.$nextTick(() => {
							//
							// 	this.$onEvent(this.getComponent().$el, 'mouseleave', this.onMouseLeaveEvent);
							// });

							this.$onEvent(this.$el, 'mouseleave', this.onMouseLeaveEvent);
						})

					}, timeout.enter);

					break;
				}

				case 'click': {

					this.handleVisibleChange(!this.isVisible, e);
					// this.setIsVisible(!this.isVisible, e);
					break;
				}

				case 'contextmenu': {

					e.preventDefault();
					this.handleVisibleChange(e, e);
					break;
				}

				case 'keydown': {

					if(~[13,38,40].indexOf(e.keyCode)){

						this.handleVisibleChange(e, e);
					}

					break;
				}
			}
		},
		getComponent(){

			return this.$slots.overlay.componentInstance;
		},
		renderOverlay(){

			let overlay = getComponentFromProp(this, 'overlay');
			if(isArray(overlay)) overlay = overlay[0];
			let propsData = getPropsData(overlay);
			return this.$slots.overlay = cloneElement(overlay, {props: Object.assign({prefixCls: 'ant-dropdown'}, propsData)});
		}
	},
	render(){

		let props = {

			attrs: {tabindex: 0},
			on: {... this.$listeners}
		};

		for(let event of this.trigger){

			if(!event) continue;
			props.on[event] = this.handleTriggerEvent;
		}

		return (

			<div {... props}>
				{this.$slots.default && this.$slots.default[0]}
				{this.wasVisible && this.renderOverlay()}
			</div>
		)
	}
};

export default Dropdown;