export default {

	name: "GDropdownMenuItem",
	inject: ['dropdown', 'dropdownMenu'],
	props: {

		disabled: {type: Boolean, default: false}
	},
	created(){

		this.dropdownMenu.addItemVm(this);
	},
	render(){

		let props = {

			attrs: {

				tabindex: 0,
				role: 'menuitem'
			},
			class: {

				'ant-dropdown-menu-item': true,
				'ant-dropdown-menu-item-disabled': this.disabled
			}
		};

		if(!this.disabled){

			let $listeners = this.$listeners;

			props.on = Object.assign({}, $listeners, {

				click: (e) => {

					$listeners.click && $listeners.click(e);
					this.dropdown.handleItemClick(this.$vnode.key, e);
				}
			});
		}

		return (

			<li {...props}>
				{this.$slots.default}
			</li>
		)
	}
}