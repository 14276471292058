import {isVNode} from "@/resources/util";
import animate from "ant-design-vue/es/_util/css-animation";
import EventMixin from "@/mixins/EventMixin.js";
import {isFunction} from "@/resources/util";

let cId = 0;

export default {

	name: "GContainer",
	props: {

		appendTo: {type: [Element, Function], default: null},
		wrapperClassName: {type: [String, Object]},
		getComponent: Function,
		renderComponent: Function
	},
	created(){

		this.id = ++cId;
	},
	mounted(){

		this.appendContainerEl(this.getAppendTo());
	},
	data(){

		return {}
	},
	methods: {

		getAppendTo(){

			return (isFunction(this.appendTo) ? this.appendTo() : this.appendTo) || window.document.body;
		},
		getParentComponent(){

			return this.$parent;
		},
		appendContainerEl(el){

			el.appendChild(this.$el);
		},
		// createBodyContainer(){
		//
		// 	this.container = document.createElement('div');
		// 	this.container.setAttribute('style', 'top: 0; left: 0; position: fixed;');
		// 	this.getDocumentBody().appendChild(this.container);
		// },
		destroyContainer(){

			if(this.$el.parentNode) {

				this.getAppendTo().removeChild(this.$el);
			}
		}
	},
	render(){

		let props = {

			attrs: {id: this.id},
			class: {'g-container': true}
		};

		if(this.$props.wrapperClassName) props.class = {...props.class, [this.$props.wrapperClassName]: true};

		return (

			<div {...props}>
				{this.renderComponent()}
			</div>
		);
	},
	beforeDestroy(){

		this.destroyContainer();
	}
}