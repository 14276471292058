export default {

	name: 'GMenuItemDivider',
	render() {

		const prefixCls = 'ant-dropdown-menu-item-divider';

		const classString = {
			[prefixCls]: true
		};

		return (
			<li class={classString}/>
		);
	},
};