import Container from "./Container";
import DropdownMenu from './Menu';
import Dropdown from './Dropdown';
import animate from "ant-design-vue/es/_util/css-animation";

export default {

	name: "GDropdownSubMenu",
	props: {

		title: {type: String, required: false},
		visible: {type: Boolean, required: false}
	},
	inject: ['dropdown', 'dropdownMenu'],
	created(){

		this.dropdownMenu.addItemVm(this);
	},
	data(){

		return {

			wasVisible: false,
			isVisible: false,
			style: {}
		}
	},
	methods: {

		isOpen(){

			return this.isVisible;
		},
		getMenuRef(){

			return this.$refs.menu;
		},
		getParentMenu(){

			return this.dropdownMenu;
		},
		setIsVisible(is, e){

			this.isVisible = is;

			let fn = () => {

				this.$nextTick(() => {

					this.getMenuRef().setIsVisible(is, e, 'submenu');
				})
			};

			if(!this.wasVisible) {

				if(is) {

					this.wasVisible = true;
					this.$nextTick(fn);
				}

			} else {

				fn();
			}
		}
	},
	render(){

		let props = {

			attrs: {tabindex: 0, role: 'menuitem'},
			class: {

				'ant-dropdown-menu-submenu': true,
				'ant-dropdown-menu-submenu-vertical': true,
				'opened': this.isVisible
			},
			on: {

				click: (e) => {

					this.setIsVisible(!this.isVisible, this.$el);
					this.getParentMenu().setCurrentItemEl(this.$el);

					if(!this.isVisible){

						this.getMenuRef().setItemsVisible(false, e);
					}
				},
				mouseenter: (e) => {

					if(this.mouseEnterTimeout) clearTimeout(this.mouseEnterTimeout);
					if(this.isVisible) return;

					this.mouseEnterTimeout = setTimeout(() => {

						this.getParentMenu().setCurrentItemEl(this.$el);
						this.setIsVisible(true, e);

						// this.getParentMenu().handleOpenSubMenu(true, e);

					}, 100);
				},
				mouseleave: (e) => {

					if(this.mouseLeaveTimeout) clearTimeout(this.mouseLeaveTimeout);

					if(e.toElement && e.toElement.classList && e.toElement.classList.contains('ant-dropdown-menu-submenu')) return;

					this.mouseLeaveTimeout = setTimeout(() => {

						if(this.isVisible && e.toElement !== this.dropdownMenu.getMenuWrapperRef()) {

							this.setIsVisible(false, e);
							this.getMenuRef().setItemsVisible(false, e);
						}

					}, 100);
				}
			}
		};

		return (
			
			<li
				{...props}
			>
				<div class="ant-dropdown-menu-submenu-title">
					{this.$slots.title || <span>{this.title}</span>}
					<span class="ant-dropdown-menu-submenu-arrow">
                        <i class="ant-dropdown-menu-submenu-arrow-icon anticon anticon-right">
                            <svg
								viewBox="64 64 896 896" data-icon="right" width="1em" height="1em" fill="currentColor"
								aria-hidden="true"><path
								d="M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z"/></svg>
                        </i>
                    </span>
				</div>
				{this.wasVisible && (

					<DropdownMenu ref="menu" prefixCls='ant-dropdown-menu-submenu' classesSuffixes={['placement-rightTop', 'popup']} appendTo={() => {

						return this.dropdownMenu.$refs.wrapper;

					}}>
						{this.$slots.default}
					</DropdownMenu>
				)}
			</li>
		);
	}
}