import './index.less';
import AntTable from 'ant-design-vue/es/table';
import { getDeepProperty, isFunction, isObject } from "@/resources/util";
import { hasProp, mergeProps } from "ant-design-vue/es/_util/props-util";
import EventMixin from "@/mixins/EventMixin";

import GDropdown from '@/components/dropdown';

function getAntTableContentVm(vm) {

	for (let key in vm.$children) {

		let child = vm.$children[key];

		let { $options: { propsData = {} } } = child;

		if (propsData.prefixCls === "ant-table") {

			return vm;
		}

		let nest = getAntTableContentVm(child);
		if (nest) return nest;
	}
}

function getAntTableRowsVm(vm) {

	for (let k in vm.$children) {

		let child = vm.$children[k];

		let { $options: { propsData = {} } } = child;

		if (propsData.prefixCls === "ant-table-row") {

			return vm.$children;
		}

		let nest = getAntTableRowsVm(child);
		if (nest) {

			return nest;
		}
	}
}


export default {

	mixins: [EventMixin],
	props: {

		...AntTable.props,
		customRowDropdownOverlay: {

			type: Function,
			required: false
		}
	},
	created() {

		// this.dropdownVNode = '';
	},
	mounted() {

		const $antTable = this.$refs.antTable;
		let $antTableContentVm = getAntTableContentVm($antTable);

		if ($antTableContentVm) {

			this.$antTableContentEl = $antTableContentVm.$el.childNodes[0];
			this.$antTableContentEl.setAttribute('tabindex', -1);
		}


		this.focusTable();
	},
	data() {

		return {

			dropdownVNode: null,
			row: {

				current: {

					index: null,
					vm: null
				}
			}
		}
	},
	methods: {

		focusTable() {

			this.$el.focus();
		},
		focusRow(index) {

			const { current } = this.row;
			const currentRow = getAntTableRowsVm(this.$refs.antTable)[index + 1];
			current.index = index;
			current.vm = currentRow;
			currentRow.$el.focus();
		},
		editRow(record, index) {

			this.$emit('editRow', record[this.$props.rowKey], record);
		},
		handleContextMenu(e, record, index) {

			const { target } = e;

			e.preventDefault();
		},
	},
	render() {

		const elProps = {};

		// let selections = [];

		// if(hasProp(this, 'rowSelection') && this.$props.rowSelection.selectedRowKeys.length){

		// 	selections.push({

		// 		key: 'clear-all',
		// 		text: `Limpar toda a seleção (${this.$props.rowSelection.selectedRowKeys.length} itens)`,
		// 		onSelect: () => {

		// 			this.$props.rowSelection.selectedRowKeys = [];
		// 			this.focusTable();
		// 		}
		// 	});
		// }

		const antTableProps = {

			// attrs: {tabindex: 5},
			scopedSlots: {
				... this.$scopedSlots
			},
			props: {

				... this.$props,
				// rowSelection: {

				// 	... this.$props.rowSelection,
				// 	hideDefaultSelections: false,
				// 	selections
				// },
				customRow: (record, index) => {

					let customRowProps = (this.$props.customRow && this.$props.customRow(record, index)) || {};

					let props = {

						ref: 'row-' + index,
						attrs: { tabindex: -1 },
						on: {

							click: (e) => {

								this.focusRow(index + 1);
							},
							dblclick: (e) => {

								this.editRow(record, index);
							}
						}
					};

					if (isFunction(customRowProps.renderContextMenu)) {

						props.on.contextmenu = (e) => {

							this.handleContextMenu(e, record, index);
							this.dropdownVNode = customRowProps.renderContextMenu(e);

							if (this.dropdownVNode.componentOptions.Ctor.extendOptions.name !== GDropdown.name) {

								throw new Error(`Custom row contextmenu needs to be a GDropdown instance.`);
							}

							this.dropdownVNode.componentOptions.listeners.visibleChange = (is) => {

								if (!is) {

									this.focusRow(index);
								}

								this.dropdownVNode = customRowProps.renderContextMenu(is);
							};

							//console.log((this.dropdownVNode));
						}
					}

					return mergeProps(customRowProps, props);
				}
			}
		};

		return (

			<div class="gxz-table-wrapper" tabindex="-1" {...elProps}>
				{/*{JSON.stringify({... this.row.current, vm: 'recursive'}, null, 4)}*/}
				<AntTable ref="antTable" {...antTableProps} />
				{this.dropdownVNode}
			</div>
		)
	}
}
